
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedWheelsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('tr', {
                    'className': 'wishlist_row',
                    'data-diameter': this.wheel_diameter
                }, _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('a', {
                    'href': this.url,
                    'className': 'product_link'
                }, this.cror_mpn)), _createElement('th', { 'className': 'wishlist_col text-center' }, this.wheel_width), _createElement('th', { 'className': 'wishlist_col text-center' }, this.wheel_bolt_pattern?.join(', ')), _createElement('th', { 'className': 'wishlist_col text-center' }, this.wheel_offset), _createElement('th', { 'className': 'wishlist_col text-center' }, this.wheel_load_rating), _createElement('th', { 'className': 'wishlist_col text-center' }, this.wheel_finish), _createElement('th', { 'className': 'wishlist_col text-center' }, '\n          ', this.compare_at_price ? this.formatPrice(this.compare_at_price) : this.formatPrice(this.price), '\n        '), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '9761'
                    }, '\n                ', this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? window.themeSettings.locales.special_order : window.themeSettings.locales.select_options, '\n              ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '14181'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '14183'
                    }),
                    _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock ? true : false,
                        'key': '14185'
                    }, '\n                ', this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? window.themeSettings.locales.special_order : window.themeSettings.locales.add_to_cart, '\n              ')
                ] : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-items' }, _createElement('div', { 'className': 'section-header text-left' }, _createElement('h2', {}, 'Related Wheels')), _createElement('ul', { 'className': 'nav nav-tabs nav-tabs-large' }), _createElement('table', { 'className': 'wishlist_table' }, _createElement('thead', {}, _createElement('tr', { 'className': 'wishlist_row' }, _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Part Number')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Width')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Bolt Pattern')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Offset')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Load Rating')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Finish')), _createElement('th', { 'className': 'wishlist_col text-center' }, _createElement('span', { 'className': 'text' }, 'Price')), _createElement('th', { 'className': 'wishlist_col wishlist_add text-center' }, _createElement('span', { 'className': 'text' }, 'Add to Cart')))), _createElement.apply(this, [
        'tbody',
        { 'className': 'cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])));
}
        export const componentNames = []